/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles.css"; // Import your custom CSS file

// const databaseURL = "https://bubbly-cascade-419919-default-rtdb.firebaseio.com";
const databaseURL = "https://payment-c9754-default-rtdb.firebaseio.com/"

function Home() {
  // const upiIdRef = useRef(null);
  const [upiId, setUpiId] = useState('');
  const fileInputRef = useRef(null);
  const [user, setUser] = useState({
    Transactionid: "",
    File: null,
  });
  const [previewUrl, setPreviewUrl] = useState("");
  const [selectedMethod, setSelectedMethod] = useState("upi");
  const [accountDetails, setAccountDetails] = useState({
    holderName: "",
    accountNumber: "",
    ifscCode: "",
  });

  const handleToggle = (method) => {
    setSelectedMethod(method);
  };

  useEffect(() => {
    // Fetch UPI ID and account details when the component mounts
    const fetchDetails = async () => {
      try {
        const [upiResponse, accountResponse] = await Promise.all([
          fetch(`${databaseURL}/Account/UpiID.json`),
          fetch(`${databaseURL}/Account/AccountDetails.json`),
        ]);

        const upiData = await upiResponse.json();
        const accountData = await accountResponse.json();
       console.log({upiData,accountData})
        if (upiData && upiData.UpiId) {
            setUpiId(upiData.UpiId); 
          }
         else {
          console.error("UPI data is missing or incorrectly formatted.");
        }

        if (accountData) {
          setAccountDetails(accountData);
        }
      } catch (error) {
        console.error("Error fetching details:", error);
        toast.error("Error fetching account details", { autoClose: 3000 });
      }
    };

    fetchDetails();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUser((prevUser) => ({ ...prevUser, File: reader.result }));
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { Transactionid, File } = user;

    const formData = { Transactionid, File };

    try {
      const response = await fetch(`${databaseURL}/UserData.json`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Message sent successfully", { autoClose: 3000 });
        setUser({ Transactionid: "", File: null });
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
      } else {
        toast.error("Error occurred while sending the message", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("Network error occurred", { autoClose: 3000 });
      console.error("Error occurred:", error);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => toast.success("Copied to clipboard!", { autoClose: 3000 }))
      .catch((err) => {
        toast.error("Failed to copy", { autoClose: 3000 });
        console.error("Failed to copy: ", err);
      });
  };
  return (
    <div className="container-fluid min-vh-100 py-3">
      <div className="row">
        <div className="col-md-8 col-lg-12">
          <div className="p-4 border rounded shadow bg-white">
            <h2 className="text-center mb-4">Payment Gateway</h2>
            <div className="d-flex align-items-center gap-3 payment-method mb-3">
              <button
                type="button"
                onClick={() => handleToggle("upi")}
                className={selectedMethod === "upi" ? "active" : ""}
              >
                UPI Pay
              </button>
              <button
                type="button"
                onClick={() => handleToggle("creditCard")}
                className={selectedMethod === "creditCard" ? "active" : ""}
              >
                Account Details
              </button>
            </div>

            {selectedMethod === "upi" && (
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label htmlFor="upiId">UPI ID</label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Upiid"
                      className="form-control"
                      id="upiId"
                      placeholder="Enter your UPI ID"
                      // ref={upiIdRef}
                      value={upiId}
                      readOnly
                    />
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={() => copyToClipboard(upiId)}
                    >
                      Copy
                    </button>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="transactionId">Transaction ID</label>
                  <input
                    type="text"
                    className="form-control"
                    id="transactionId"
                    placeholder="Enter Transaction ID"
                    name="Transactionid"
                    value={user.Transactionid}
                    required
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="fileUpload">Upload a file</label>
                  <input
                    type="file"
                    className="form-control-file form-control"
                    id="fileUpload"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                </div>
                {previewUrl && (
                  <div className="form-group mb-3">
                    <div className="text-center">
                      <img
                        src={previewUrl}
                        alt="Image Preview"
                        style={{ maxWidth: "100%", maxHeight: "300px" }}
                      />
                    </div>
                  </div>
                )}

                <button type="submit" className="btn btn-primary btn-block">
                  Submit
                </button>
              </form>
            )}

            {selectedMethod === "creditCard" && (
              <div className="d-flex gap-3 flex-column flex-lg-row mt-3">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={accountDetails.holderName}
                    readOnly
                  />
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => copyToClipboard(accountDetails.holderName)}
                  >
                    Copy
                  </button>
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={accountDetails.accountNumber}
                    readOnly
                  />
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() =>
                      copyToClipboard(accountDetails.accountNumber)
                    }
                  >
                    Copy
                  </button>
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={accountDetails.ifscCode}
                    readOnly
                  />
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => copyToClipboard(accountDetails.ifscCode)}
                  >
                    Copy
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center gap-3 logo-pay p-3 pt-5 justify-content-center">
        <button type="button">
          <img src={require("../images/google-pay.png")} alt="Google Pay" />
        </button>
        <button type="button">
          <img src={require("../images/phonepay.png")} alt="PhonePay" />
        </button>
        <button>
          <img src={require("../images/paytm.png")} alt="Paytm" />
        </button>
        <button type="button">
          <img src={require("../images/bhart.png")} alt="Bharat" />
        </button>
      </div>
      <div className="text-center mt-3" style={{ fontSize: "18px" }}>
        Powered by{" "}
        <img
          src={require("../images/upi_logo_icon_169316.png")}
          style={{ width: "50px", height: "50px" }}
          alt="UPI"
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default Home;
