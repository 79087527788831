

import Home from "./component/Home";
function App() {
  return (
    <>
      {/* Uncomment the Home component if needed */}
      <Home/>
    </>
  );
}

export default App;
